<template>
	<div class="page">
		<el-row class="tool-bar">
			<el-col :span="12">
				<search-tool v-model="search" @input="$utils('search', getKarteDoplate)"/>
			</el-col>
		</el-row>
		<el-row>
			<!--Table-->
			<el-table v-if="karteDoplate.length"
						:data="karteDoplate"
						ref="table"
						:default-sort="sortBy"
						@sort-change="sort => $utils('changeSort', sort, getKarteDoplate)"
						v-loading="gridLoad"
						border
						stripe>
				<el-table-column
						align="left"
						prop="datum"
						:label="$t('karte.datum')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatDate', scope.row.datum, 'DD.MM.YYYY HH:mm')}}
					</template>
				</el-table-column>
				<el-table-column
						align="left"
						prop="platilac"
						:label="$t('karte.platilac')"
						sortable="custom">
				</el-table-column>
				<el-table-column
						align="center"
						prop="novi_datum_polaska"
						:label="$t('karte.noviDatumPolaska')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatDate', scope.row.novi_datum_polaska, 'DD.MM.YYYY')}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="novi_datum_povratka"
						:label="$t('karte.noviDatumPovratka')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatDate', scope.row.novi_datum_povratka, 'DD.MM.YYYY')}}
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="broj_karte"
						:label="$t('karte.brojKarteNovi')"
						sortable="custom">
					<template slot-scope="scope">
						<el-button type="success"  @click="$utils('goTo', '/karte?brojKarte=' + scope.row.broj_karte)">{{scope.row.broj_karte}}</el-button>
					</template>
				</el-table-column>
				<el-table-column
						align="center"
						prop="broj_karte_stari"
						:label="$t('karte.brojKarteStari')"
						sortable="custom">
					<template slot-scope="scope">
						<el-button type="danger"  @click="$utils('goTo', '/karte?brojKarte=' + scope.row.broj_karte_stari)">{{scope.row.broj_karte_stari}}</el-button>
					</template>
				</el-table-column>
				<el-table-column
						align="right"
						prop="ukupna_cena"
						:label="$t('karte.ukupnaCena')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.ukupna_cena, 2)}}
					</template>
				</el-table-column>
				<el-table-column
						align="right"
						prop="doplata"
						:label="$t('karte.doplata')"
						sortable="custom">
					<template slot-scope="scope">
						{{$utils('formatBroja', scope.row.doplata, 2)}}
					</template>
				</el-table-column>
			</el-table>
			<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
		</el-row>
		<br>
		<el-row>
			<pagination v-model="pagination" @input="getKarteDoplate" ref="pagination"/>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'karte-doplate-list',
	data() {
		return {
			karteDoplate: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'token',
				order: ''
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('karte.karteDoplate')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getKarteDoplate()]);
	},
	methods: {
		getKarteDoplate() {
			this.gridLoad = true;
			return this.$get('karteDoplate', this.$utils('getParams')).then(data => {
				this.karteDoplate = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		}
	}
};
</script>
